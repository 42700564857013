import { LS } from "./LocalStorage";

export const isLogin = () => {
  const token = LS.getItem("USER_TOKEN");
  if (token) {
    return true;
  } else {
    return false;
  }
};
