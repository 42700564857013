import moment from "moment";
import history from "routes/history";
import { LocalStorage } from "utils";

export const DATE_FORMAT = "DD/MM/YYYY, hh:mm a";

export const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";

/**
 * @description Format datetime string which contains datetime in utc to local date time
 */
export function formatUTCDateTime(dateTimeString, formatString = DATE_FORMAT) {
  return moment.utc(dateTimeString).utcOffset(moment().utcOffset()).format(formatString);
}

export function updateUserPreferences(id, value) {
  if (!id || typeof id !== "string" || id.trim() === "") {
    console.log("Error updating user preferences, id/value undefined");
    console.log("\tid: " + id + ", value: " + value);
    return false;
  }

  var userPrefs = sessionStorage.getItem("USER_PREFS");
  var preferences = {};
  if (userPrefs && userPrefs !== "undefined" && userPrefs !== "null") {
    preferences = JSON.parse(userPrefs);
  }

  preferences[id] = value;
  try {
    sessionStorage.setItem("USER_PREFS", JSON.stringify(preferences));
    return true;
  } catch (err) {
    console.log("Error updating user preferences.\n\t\t" + err.message + "id: " + id + ", value: " + value);
    return false;
  }
}

export function getUserPreferences(id) {
  if (!id || typeof id !== "string" || id.trim() === "") {
    console.log("Error loading user preferences, id undefined");
    console.log("\tid: " + id);
    return null;
  }

  try {
    var userPrefs = sessionStorage.getItem("USER_PREFS");
    if (userPrefs && userPrefs !== "undefined") {
      var preferences = JSON.parse(userPrefs);
      return preferences[id] ?? null;
    }
    return null;
  } catch (err) {
    console.error("Error getting user preferences (" + id + "), Error:\n\t\t" + err.message);
    return null;
  }
}

export async function fetchBase64Data(dataUrl) {
  try {
    const response = await fetch(dataUrl, { cache: "no-cache" });
    return response.text();
  } catch (error) {
    console.error("error loading RTF document: ", error.message);
  }
}

export function openInNewTab(url) {
  var win = window.open(url, "_blank");
  win.focus();
}

export function removeAllChildNodes(element) {
  if (!element) return;
  // Loop through all child nodes of the element
  while (element.firstChild) {
    // Remove the first child node from the element
    element.removeChild(element.firstChild);
  }
}

export function generateRTFBase64UsingText(textString = " ") {
  const rtfString = `{\\rtf1\\deff0{\\fonttbl{\\f0 Calibri;}{\\f1 Bembo Std;}}{\\colortbl ;\\red0\\green0\\blue255 ;}{\\*\\defchp \\f1\\fs20}{\\stylesheet {\\ql\\f1\\fs20 Normal;}{\\*\\cs1\\f1\\fs20 Default Paragraph Font;}{\\*\\cs2\\ul\\f1\\cf1 Hyperlink;}{\\*\\ts3\\tsrowd\\fs22\\ql\\tsvertalt\\cltxlrtb Normal Table;}}{\\*\\listoverridetable}{\\info}\\nouicompat\\splytwnine\\htmautsp\\expshrtn\\spltpgpar\\deftab720\\sectd\\marglsxn403\\margrsxn403\\margtsxn288\\margbsxn288\\headery720\\footery10\\pgwsxn5904\\pghsxn8136\\cols1\\colsx720{\\footerr\\pard\\plain\\qc{\\field\\dxfldcodeview{\\*\\fldinst{\\f1\\cf0 Page}}{\\fldrslt}}\\f1\\cf0\\par}\\pard\\plain\\ql{\\f1\\cf0 AI_Generated_text}\\f1\\fs20\\cf0\\par}`;

  // const rtfLineBreak = "\n\\line ";
  // const rtfLineBreak = "}\\f1\\cf0\\par\\pard\\plain\\qj{\\f1\\cf0 \\line }{\\f1\\cf0 ";
  const rtfLineBreak = "}\\f1\\cf0\\par\\pard\\plain\\qj{\\f1\\cf0 }{\\f1\\cf0 ";
  const replaceString = textString.replace(/\n/g, rtfLineBreak);

  const newText = rtfString.replace("AI_Generated_text", replaceString);

  const newRtfBuffer = Buffer.from(newText, "utf8");
  const newBase64Rtf = newRtfBuffer.toString("base64");

  return newBase64Rtf;
}

export function handleRequestCatch(toast, error, genericMessage = "Unknown error.") {
  if (error.response && error.response.status && error.response.status === 401) {
    LocalStorage.ClearToken();
    // user token expired, re-route to sign-in page
    toast.warning("Session expired, Please sign in");
    history.push("/signin");
    return;
  }

  var message = genericMessage;
  if (
    error &&
    error.response &&
    error.response.data &&
    typeof error.response.data === "string" &&
    error.response.data.trim().length > 0 &&
    error.response.data.trim().length < 200
  ) {
    message = error.response.data.trim();
  }
  toast.error(message);
}

export async function sleep(milliseconds = 100) {
  return await new Promise((r) => setTimeout(r, milliseconds));
}


export function validateEmail(email) {
  // Regular expression pattern for validating email addresses
  // const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const emailPattern = /^[a-zA-Z0-9._-]+(\+[a-zA-Z0-9._-]+)?@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  return emailPattern.test(email);
}
