export const EnumUserType = {
  FreeUser: 0,
  PaidUser: 1,
  PremiumUser: 2,
};

const UNLIMITED_COUNT = 999999;

export const SUBSCRIPTIONS = {
  [EnumUserType.FreeUser]: {
    maxDocuments: 1,
    maxPrints: 2,
    maxImagePerOurzine: 3,
    maxAITextGeneration: 1,
    editBackCoverText: false,
  },
  [EnumUserType.PaidUser]: {
    maxDocuments: 20,
    maxPrints: UNLIMITED_COUNT,
    maxImagePerOurzine: UNLIMITED_COUNT,
    maxAITextGeneration: UNLIMITED_COUNT,
    editBackCoverText: false,
  },
  [EnumUserType.PremiumUser]: {
    maxDocuments: UNLIMITED_COUNT,
    maxPrints: UNLIMITED_COUNT,
    maxImagePerOurzine: UNLIMITED_COUNT,
    maxAITextGeneration: UNLIMITED_COUNT,
    editBackCoverText: true,
  },
};
