import { LS } from "utils/LocalStorage";
import ConfigActionTypes from "../actionTypes/config";

const INITIAL_STATE = {
  enableAutoSave: true,
};

const USER_CONFIG_STORAGE_TOKEN = "USER_CONFIG";

function getLocalCache() {
  let storageItem = LS.getItem(USER_CONFIG_STORAGE_TOKEN);
  if (storageItem) {
    return JSON.parse(storageItem);
  } else {
    return {};
  }
}

function updateLocalCache(userConfig) {
  let cache = {};
  let storageItem = LS.getItem(USER_CONFIG_STORAGE_TOKEN);
  if (storageItem) {
    cache = JSON.parse(storageItem);
  }

  cache = { ...cache, ...userConfig };

  LS.setItem(USER_CONFIG_STORAGE_TOKEN, JSON.stringify(cache));

  return cache;
}

const configReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ConfigActionTypes.GET_USER_CONFIG: {
      let cache = getLocalCache();
      if (!cache || Object.keys(cache).length === 0) {
        updateLocalCache({ ...state });
      }
      return { ...state, ...cache };
    }
    case ConfigActionTypes.UPDATE_USER_CONFIG: {
      let updatedUserConfig = updateLocalCache({ ...state, ...action.payload });
      return { ...state, ...updatedUserConfig };
    }
    case ConfigActionTypes.TOGGLE_AUTO_SAVE: {
      // Update cache
      let enableAutoSave = !state.enableAutoSave;
      let updatedCache = updateLocalCache({ ...state, enableAutoSave });
      //

      return {
        ...state,
        ...updatedCache,
      };
    }
    default:
      return state;
  }
};

export default configReducer;
