import { toast } from "react-toastify";

export class LS {
  static _userHasSeenCookieError = false;

  static _tempStorage = {};

  static setItem = (id, item) => {
    try {
      localStorage.setItem(id, item);
    } catch (err) {
      this._handleStorageError(err);
      this._tempStorage[id] = item;
    }
  };

  static getItem = (id) => {
    try {
      return localStorage.getItem(id);
    } catch (err) {
      this._handleStorageError(err);
      if (this._tempStorage.hasOwnProperty(id)) {
        return this._tempStorage[id];
      }
      return null;
    }
  };

  static removeItem = (id) => {
    try {
      localStorage.removeItem(id);
    } catch (err) {
      this._handleStorageError(err);
      if (this._tempStorage.hasOwnProperty(id)) {
        delete this._tempStorage[id];
      }
    }
  };

  static clear = () => {
    try {
      localStorage.clear();
    } catch (err) {
      this._handleStorageError(err);
      this._tempStorage = {};
    }
  };

  _handleErrorDebounce;
  static _handleStorageError(error) {
    if (this._userHasSeenCookieError) return;
    if (this._handleErrorDebounce) clearTimeout(this._handleErrorDebounce);
    this._handleErrorDebounce = setTimeout(() => {
      if (error.name === "SecurityError") {
        this._userHasSeenCookieError = true;
        console.warn("Local/Session storage read/write permissions not available");
        const message = "Important: This site requires cookies to be enabled in your browser in order to work properly.\nPlease enable cookies in your browser settings.";
        toast.warn(message, { autoClose: 15000 });
      } else {
        console.warn("Error accessing local storage:\n\t", error.message);
      }
    }, 1000);
  }
}

const TOKEN = {
  USER_TOKEN: "USER_TOKEN",
  USER_INFO: "USER_INFO",
};
class LocalStorage {
  static StoreToken = (token) => LS.setItem(TOKEN.USER_TOKEN, token);
  static GetToken = () => LS.getItem(TOKEN.USER_TOKEN);
  static ClearToken = () => {
    this.ClearUserInfo();
    LS.removeItem(TOKEN.USER_TOKEN);
  };

  static StoreUserInfo = (user) => LS.setItem(TOKEN.USER_INFO, user);
  static GetUserInfo = () => LS.getItem(TOKEN.USER_INFO);
  static ClearUserInfo = () => {
    LS.removeItem(TOKEN.USER_INFO);
  };

  static ClearLocalstorage = () => {
    LS.clear();
  };
}
export default LocalStorage;
