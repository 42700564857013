import SignupVerification from "pages/SignupVerification";
import React, { lazy, Suspense } from "react";
import { BrowserRouter, Redirect, Switch } from "react-router-dom";

import { PRIVATE_ROUTES, ROUTES } from "./constant";
import Loader from "../components/loader/Loader";

const Landing = lazy(() => import("../pages/Landing/landing.js"));
const Signin = lazy(() => import("../pages/Authentication/Signin/Signin"));
const Signup = lazy(() => import("../pages/Authentication/Signup/Signup"));
const Forgotpassword = lazy(() => import("../pages/Authentication/Forgotpassword/ForgotPassword"));
const Resetpassword = lazy(() => import("../pages/Authentication/Resetpassword/ResetPassword"));
const Worksheet = lazy(() => import("../pages/Worksheet/Worksheet"));
const Profile = lazy(() => import("../pages/Profile/Profile"));
const HelpPage = lazy(() => import("../pages/Help"));
const PublicRoute = lazy(() => import("./Public.route"));
const PrivateRoute = lazy(() => import("./Private.route"));
const ShippingDetails = lazy(() => import("../pages/Shipping"));
const PaymentSuccess = lazy(() => import("../pages/PaymentSuccess"));
const AdminSignin = lazy(() => import("../pages/Admin/Signin/AdminSignin"));
const AdminDashboard = lazy(() => import("../pages/Admin/Dashboard/Dashboard"));
const AllPlans = lazy(() => import("../pages/Subscription/AllPlans/AllPlans"));
const TermsAndConditions = lazy(() => import("../pages/TermsAndConditions/TermsAndConditions.js"));

const publicroutesConfig = [
  {
    key: 1,
    component: Landing,
    path: ROUTES.LANDING,
  },
  {
    key: 2,
    component: Signin,
    path: ROUTES.SIGNIN,
  },
  {
    key: 3,
    component: Signup,
    path: ROUTES.SIGNUP,
  },
  {
    key: 4,
    component: HelpPage,
    path: ROUTES.HELP,
  },
  {
    key: 5,
    component: Forgotpassword,
    path: ROUTES.FORGOTPASSWORD,
  },
  {
    key: 6,
    component: Resetpassword,
    path: ROUTES.RESETPASSWORD,
  },
  {
    key: 7,
    component: AdminSignin,
    path: ROUTES.ADMIN_SIGNIN,
  },
  {
    key: 8,
    component: AllPlans,
    path: ROUTES.ALLPLANS,
  },
  {
    key: 9,
    component: TermsAndConditions,
    path: ROUTES.TERMSANDCONDITIONS,
  },
  {
    key: 10,
    component: SignupVerification,
    path: ROUTES.SIGNUP_VERIFICATION,
  },
];

const PrivateroutesConfig = [
  {
    key: 11,
    component: Worksheet,
    path: PRIVATE_ROUTES.WORKSHEET,
  },
  {
    key: 12,
    component: Profile,
    path: PRIVATE_ROUTES.PROFILE,
  },
  {
    key: 13,
    component: ShippingDetails,
    path: PRIVATE_ROUTES.SHIPPING,
  },
  {
    key: 14,
    component: PaymentSuccess,
    path: PRIVATE_ROUTES.PAYMENTSUCCESS,
  },
  {
    key: 15,
    component: AdminDashboard,
    path: PRIVATE_ROUTES.ADMIN_DASHBOARD,
  },
];

const Routes = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <Switch>
          {publicroutesConfig.map((i) => {
            return <PublicRoute key={i.key.toString()} restricted={false} component={i.component} path={i.path} exact />;
          })}

          {PrivateroutesConfig.map((i) => {
            return <PrivateRoute key={i.key.toString()} restricted={true} component={i.component} path={i.path} exact />;
          })}

          <Redirect to="/" />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routes;
