import { Button } from "antd";
import AlertIcon from "components/AlertModal/AlertIcon";
import AlertModal from "components/AlertModal/AlertModal";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import { MobileProfile } from "../../Image";
import styles from "./SignupVerification.module.css";

const SignupVerification = ({ location }) => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [alert, setAlert] = useState({
    show: false,
    title: "Are you sure ?",
    icon: AlertIcon,
    message: "",
    onConfirm: null,
  });

  useEffect(() => {
    setAlert({
      ...alert,
      show: true,
      title: "Account verification",
      message:
        "Please look in your email inbox for the account verification message.\nIf it's not in the inbox, be sure to check your spam folder.",
    });
  }, []);

  return (
    <>
      <AlertModal
        open={alert.show}
        close={() => setAlert((prev) => ({ ...prev, show: false }))}
        title={alert.title}
        Icon={alert.icon}
        message={alert.message}
      />

      <div className={styles.svtopdiv}>
        {isLoading ? <Loader /> : ""}

        <div className={styles.svleftcontainer}>
          <div className={styles.svleftchild}>
            <div>
              <img alt="rorshok-logo" src={MobileProfile} className={styles.svleftlogo} />
            </div>
          </div>
        </div>

        <div className={styles.svrightcontainer}>
          <div className={styles.svrightchild}>
            <div className={styles.svinsidecontents}>
              <h2 className={styles.svtopicheading}>Ourzine signup successful!</h2>
              <p>Please go to your email and click on the link to verify your account. Your email might be in spam.</p>
            </div>
          </div>
          <div className={styles.svbtncontainer}>
            <Button
              className={styles.svbackbtn}
              type="primary"
              htmlType="submit"
              shape="round"
              size="large"
              onClick={() => history.push("/signin")}
            >
              Go to sign in page&nbsp;&nbsp;&gt;
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
export default SignupVerification;
