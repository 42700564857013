import React from "react";
import "./App.less";
import "./App.css";
import Routes from "./routes/Routes.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import * as $ from "jquery";

document.onreadystatechange = () => {
  if (document.readyState === "complete") {
    // document ready
    // set modal click listener for closing a modal if clicked on back overlay
    // $(".modal_root").on("click", function (event) {
    //   this.style.display = "none";
    // });
    console.log("Document Ready ----");
  }
};

function App() {
  return (
    <>
      <ToastContainer limit={4} />
      <Routes />
    </>
  );
}

export default App;
