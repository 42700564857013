import { EnumUserType, SUBSCRIPTIONS } from "common/enum";
import AuthActionTypes from "../actionTypes/auth";

const INITIAL_STATE = {
  currentUser: null,
  currentUserSubscription: SUBSCRIPTIONS[EnumUserType.FreeUser],
  currentUserSettings: null,
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AuthActionTypes.UPDATE_CURRENT_USER: {
      return {
        ...state,
        currentUser: action.payload.user,
        currentUserSubscription: SUBSCRIPTIONS[action.payload.user ? action.payload.user.userType : EnumUserType.FreeUser],
      };
    }
    case AuthActionTypes.UPDATE_CURRENT_USER_SUBSCRIPTION: {
      return {
        ...state,
        currentUserSubscription: action.payload.subscription,
      };
    }
    case AuthActionTypes.UPDATE_CURRENT_USER_SETTINGS: {
      return {
        ...state,
        currentUserSettings: action.payload,
      };
    }
    default:
      return state;
  }
};

export default authReducer;
