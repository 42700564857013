export const ROUTES = {
  LANDING: "/",
  SIGNIN: "/signin",
  SIGNUP: "/signup",
  FORGOTPASSWORD: "/forgotpassword",
  RESETPASSWORD: "/resetpassword/:token",
  ADMIN_SIGNIN: "/adminsignin",
  ALLPLANS: "/allplans",
  TERMSANDCONDITIONS: "/termsandconditions",
  SIGNUP_VERIFICATION: "/signup-verification",
  HELP: "/help/:guideType",
};

export const PRIVATE_ROUTES = {
  WORKSHEET: "/worksheet",
  PROFILE: "/profile",
  SHIPPING: "/shipping/:worksheetId",
  PAYMENTSUCCESS: "/payment-success",
  ADMIN_DASHBOARD: "/admindashboard",
};