const UserWorksheetsActionTypes = {
  SET_USER_WORKSHEETS_LIST: "SET_USER_WORKSHEETS_LIST",
  ADD_NEW_USER_WORKSHEET: "ADD_NEW_USER_WORKSHEET",
  REMOVE_USER_WORKSHEET: "REMOVE_USER_WORKSHEET",
  CURRENT_WORKSHEET: "CURRENT_WORKSHEET",
  CURRENT_WORKSHEET_DATA: "CURRENT_WORKSHEET_DATA",
  CURRENT_WORKSHEET_SAVED: "CURRENT_WORKSHEET_SAVED",
};

export default UserWorksheetsActionTypes;
