import UserWorksheetsActionTypes from "redux/actionTypes/userWorksheets";

const INITIAL_STATE = {
  worksheetsList: [],
  currentWorksheet: null,
  currentWorksheetData: null,
  currentChangesSaved: true,
};

const userWorksheetsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserWorksheetsActionTypes.SET_USER_WORKSHEETS_LIST:
      return {
        ...state,
        worksheetsList: action.payload,
      };
    case UserWorksheetsActionTypes.ADD_NEW_USER_WORKSHEET:
      return {
        ...state,
        worksheetsList: [...state.worksheetsList, action.payload],
      };
    case UserWorksheetsActionTypes.REMOVE_USER_WORKSHEET:
      if (action.payload === null) {
        return state; // cancel the action
      } else {
        return {
          ...state,
          worksheetsList: state.worksheetsList.filter((worksheet) => worksheet.id !== action.payload),
        };
      }
    case UserWorksheetsActionTypes.CURRENT_WORKSHEET:
      return {
        ...state,
        currentWorksheet: action.payload,
      };
    case UserWorksheetsActionTypes.CURRENT_WORKSHEET_DATA:
      return {
        ...state,
        currentWorksheetData: action.payload,
      };
    case UserWorksheetsActionTypes.CURRENT_WORKSHEET_SAVED:
      return {
        ...state,
        currentChangesSaved: action.payload,
      };
    default:
      return state;
  }
};

export default userWorksheetsReducer;
