import { Button, Checkbox } from "antd";
import { updateUserPreferences } from "common/utils";
import React, { useRef, useState } from "react";
import CloseSquare from "../../Image/Close Square.svg";
import AlertIcon from "./AlertIcon";

const ACTION_TYPE = {
  OK: 0,
  CANCEL: 1,
  CONFIRM: 2,
};

const AlertModal = ({
  open,
  close,
  title,
  Icon = AlertIcon,
  message,
  prefsId,
  onOk,
  onConfirm,
  onCancel,
  positiveButtonTitle = "Confirm",
  negativeButtonTitle = "Cancel",
}) => {
  const [dontShowAgain, setDontShowAgain] = useState(false);

  const modal = useRef();
  const modalContent = useRef();

  function handleAction(event, action = ACTION_TYPE.CANCEL, doNothing = false) {
    if (!doNothing && dontShowAgain && prefsId && prefsId.trim() !== "") {
      var prefType = action === ACTION_TYPE.CONFIRM || action === ACTION_TYPE.OK;
      updateUserPreferences(prefsId, prefType);
    }

    handleClose(() => {
      //
      if (action === ACTION_TYPE.CANCEL) {
        if (onCancel && typeof onCancel === "function") onCancel(doNothing);
        return;
      }
      //
      if (action === ACTION_TYPE.OK) {
        if (onOk && typeof onOk === "function") onOk(event);
        return;
      }
      //
      if (action === ACTION_TYPE.CONFIRM) {
        if (onConfirm && typeof onConfirm === "function") onConfirm(event);
        return;
      }
      //
    });
  }

  function handleClose(callback) {
    if (!modal.current || !modalContent.current) {
      close();
      if (callback && typeof callback == "function") {
        callback();
      }
      return;
    }

    try {
      /*
      Start both animations at the same time.
      modal-fadeout animates for 0.5s while modal-slideout
      animates for 0.2s;
      Ahd to stopPropagation() on modalContent slideout animation
      otherwise event propagates to modal. and close() method is called
      before fadeout animation is ended.
      */

      function onModalAnimationEnd() {
        if (modal.current) modal.current.removeEventListener("animationend", onModalAnimationEnd);
        if (modalContent.current) modalContent.current.removeEventListener("animationend", onModalContentAnimationEnd);
        close();
        if (callback && typeof callback == "function") {
          callback();
        }
      }

      function onModalContentAnimationEnd(e) {
        e.stopPropagation();
      }

      modal.current.addEventListener("animationend", onModalAnimationEnd);
      modal.current.classList.add("modal-fadeout");

      modalContent.current.addEventListener("animationend", onModalContentAnimationEnd);
      modalContent.current.classList.add("modal-slideout");
    } catch (err) {
      console.warn("error modal animate out: ", err.message);
      close();
      if (callback && typeof callback == "function") {
        callback();
      }
    }
  }

  if (!open) return null;

  return (
    <div className="modal_root" ref={modal} onClick={(e) => handleClose()}>
      <div className="modal alert_modal" ref={modalContent} onClick={(e) => e.stopPropagation()}>
        <div className="modal_header">
          <div className="header_button left_icon"></div>
          <div className="title">{title}</div>
          <img alt="modal-close-icon" src={CloseSquare} className="header_button right_icon" onClick={(e) => handleAction(e, ACTION_TYPE.CANCEL, true)} />
        </div>
        <div className="modal_body">
          <div className="modal_body_content">
            {/* <img alt="delete-bin-icon" src={TickSquare} className={styles.profiledeleteicon} /> */}
            <p className="alert_modal_message">{message}</p>
          </div>
          {prefsId && prefsId.trim() !== "" && (
            <Checkbox className="modal_user_pref_checkbox" onChange={(e) => setDontShowAgain(e.target.checked)}>
              Never show this again
            </Checkbox>
          )}
        </div>
        <div className={`modal_footer ${onConfirm ? "modal_footer_confirm" : ""}`}>
          {onConfirm ? (
            <>
              <Button
                type="primary"
                htmlType="submit"
                shape="round"
                size="large"
                className="modal_action_button modal_action_button_neg"
                onClick={(e) => handleAction(e, ACTION_TYPE.CANCEL)}
              >
                {negativeButtonTitle}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                shape="round"
                size="large"
                className="modal_action_button modal_action_button_primary"
                onClick={(e) => handleAction(e, ACTION_TYPE.CONFIRM)}
              >
                {positiveButtonTitle}
              </Button>
            </>
          ) : (
            <Button
              type="primary"
              htmlType="submit"
              shape="round"
              size="large"
              className="modal_action_button modal_action_button_primary"
              onClick={(e) => handleAction(e, ACTION_TYPE.OK)}
            >
              Ok
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(AlertModal);
