import React from "react";

const AlertIcon = (props) => {
  return (
    <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" {...props}>
      <path
        style={{ fill: "currentcolor" }}
        d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"
      ></path>
    </svg>
  );
};

export default AlertIcon;
