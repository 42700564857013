import { combineReducers } from "redux";
import auth from "./reducers/auth";
import userWorksheets from "./reducers/user_worksheets";
import config from "./reducers/config";

const rootReducer = combineReducers({
  config,
  auth,
  userWorksheets,
});

export default rootReducer;
